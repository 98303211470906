import { SUBSCRIPTION_FIELDS } from "@/graphql/subscription/subscription";
import { _Subscription } from "@/graphql/types";
import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";

type SubscriptionsData = {
  subscriptions: _Subscription[];
};

const SUBSCRIPTION = gql`    
    query Subscriptions {
        subscriptions {
            ${SUBSCRIPTION_FIELDS}
        }
    }
`;

export const useSubscriptions = () => {
  const { loading, result } = useQuery<SubscriptionsData>(SUBSCRIPTION);
  const subscriptions = useResult<
    SubscriptionsData,
    _Subscription[],
    _Subscription[]
  >(result, [], (res) => res.subscriptions);
  return {
    loading,
    subscriptions,
  };
};
