import {
  MutationUpdateSubscriptionArgs,
  _Subscription,
  UpdateSubscriptionInput,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { SUBSCRIPTION_FIELDS } from "@/graphql/subscription/subscription";
import { useMutation } from "@vue/apollo-composable";
import { ref, reactive, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

type UpdateSubscriptionData = {
  updateSubscription: _Subscription;
};

const UPDATE_SUBSCRIPTION = gql`
    mutation UpdateSubscription($input:UpdateSubscriptionInput!){
        updateSubscription(input:$input){
            ${SUBSCRIPTION_FIELDS}
        }
    }
`;

export const useUpdateSubscription = () => {
  const dialog = ref(false);
  const sliderValue = ref(0);
  const toast = useToast();
  const { t } = useI18n();
  const input = reactive<UpdateSubscriptionInput>({
    id: 0,
    cost: 0,
    currency: "XOF",
  });
  function setInput(subscription: _Subscription) {
    Object.keys(input).forEach((key) => {
      input[key] = subscription[key];
    });
    dialog.value = true;
  }
  function onCloseDialog() {
    input.id = 0;
    sliderValue.value = 0;
  }
  watch(
    () => sliderValue.value,
    (curr, old) => {
      input.cost += curr - old;
    }
  );
  const {
    loading: loadingUpdate,
    mutate,
    onDone,
  } = useMutation<UpdateSubscriptionData, MutationUpdateSubscriptionArgs>(
    UPDATE_SUBSCRIPTION
  );

  onDone(({ data }) => {
    const success = !!data?.updateSubscription;
    dialog.value = !success;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("update.title"),
      detail: t(`update.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function updateSubscription() {
    void mutate({ input });
  }
  return {
    loadingUpdate,
    updateSubscription,
    setInput,
    input,
    dialog,
    sliderValue,
    onCloseDialog,
  };
};
