
import { defineComponent } from "vue";
import { useSubscriptions } from "@/graphql/subscription/subscriptions";
import { useUpdateSubscription } from "@/graphql/subscription/update-subscription";

export default defineComponent({
  name: "Subscription",
  setup() {
    return {
      ...useSubscriptions(),
      ...useUpdateSubscription(),
    };
  },
});
